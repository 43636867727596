import React from 'react';

import carousel_1 from '../images/carousel_1.png'
import carousel_2 from '../images/carousel_2.png'
import carousel_3 from '../images/carousel_3.png'
import carousel_4 from '../images/carousel_4.png'
import carousel_5 from '../images/carousel_5.png'
import carousel_6 from '../images/carousel_6.png'

const Carousel = () => {
    return (
        <div className={'container'}>
            <div className="row">
                <div className="col-12">
                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel"
                         data-bs-interval='3000'>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-2 mb-xl-0 mb-3  d-flex justify-content-center">
                                        <img className="d-xl-block" src={carousel_1} alt="First slide"/>
                                    </div>
                                    <div className="col-md-2 mb-xl-0 mb-3  d-flex justify-content-center">
                                        <img className="d-xl-block " src={carousel_2} alt="First slide"/>
                                    </div>
                                    <div className="col-md-2 mb-xl-0 mb-3  d-flex justify-content-center">
                                        <img className="d-xl-block  " src={carousel_3} alt="First slide"/>
                                    </div>
                                    <div className="col-md-2 mb-xl-0 mb-3  d-flex justify-content-center">
                                        <img className="d-xl-block  " src={carousel_4} alt="First slide"/>
                                    </div>
                                    <div className="col-md-2 mb-xl-0 mb-3  d-flex justify-content-center">
                                        <img className="d-xl-block  " src={carousel_5} alt="First slide"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-2 mb-xl-0 mb-3  d-flex justify-content-center">
                                        <img className="d-xl-block  " src={carousel_2} alt="First slide"/>
                                    </div>
                                    <div className="col-md-2 mb-xl-0 mb-3  d-flex justify-content-center">
                                        <img className="d-xl-block  " src={carousel_3} alt="First slide"/>
                                    </div>
                                    <div className="col-md-2 mb-xl-0 mb-3  d-flex justify-content-center">
                                        <img className="d-xl-block  " src={carousel_4} alt="First slide"/>
                                    </div>
                                    <div className="col-md-2 mb-xl-0 mb-3  d-flex justify-content-center">
                                        <img className="d-xl-block  " src={carousel_5} alt="First slide"/>
                                    </div>
                                    <div className="col-md-2 mb-xl-0 mb-3  d-flex justify-content-center">
                                        <img className="d-xl-block " src={carousel_6} alt="First slide"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Carousel;