import React, {useEffect} from 'react';
import Navbar from "./Navbar";
import graphOne from "../images/graph_one.svg";
import contactUs from '../images/contact-us.svg'
import Footer from "./Footer";

import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import EmailIcon from '@mui/icons-material/Email';

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className={'home-main'}>
            <section className={'banner-main'}>
                <Navbar/>
            </section>
            <section className={'contact-us'}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className={'font-roboto'}>Contact Us</h1>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-xl-6 col-lg-12">
                            <img className={'w-100'} src={contactUs} alt=""/>
                        </div>
                        <div
                            className="col-xl-6 col-lg-12 d-flex flex-column justify-content-center align-items-center">
                            <div className={'contact-form shadow-lg  mb-5 bg-white rounded rounded-3'}>
                                <div className="row align-items-center text-center">
                                    <div className="col-12 text-center px-5">
                                        <div className={'row'}>
                                            <h6 className={'font-roboto'}>Contact</h6>
                                            <h2 className={'font-roboto'}>Get in touch</h2>
                                            <p className={'font-barlow'}>Don't hesitate to contact us</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 d-flex flex-column  gap-3">
                                        <div className={'text-center'}>
                                            <HeadsetMicIcon sx={{color: '#00A15D'}}/>
                                            <span className={'mx-3 font-roboto'}>+92 323 5858843</span>
                                        </div>
                                        <div className={'text-center'}>
                                            <HeadsetMicIcon sx={{color: '#00A15D'}}/>
                                            <span className={'mx-3 font-roboto'}>+92 312 4832466</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <p className={'font-barlow text-center'}>For consultation and discussions:</p>
                                    <div className="col-12 d-flex flex-column gap-3 align-items-center">
                                        <div>
                                            <EmailIcon sx={{color: '#00A15D'}}/>
                                            <span className={'mx-3 font-roboto'}>sales@dsanalytix.com</span>
                                        </div>
                                        <div>
                                            <EmailIcon sx={{color: '#00A15D'}}/>
                                            <span className={'mx-3 font-roboto'}>abdal@dsanalytix.com</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'footer-banner'}>
                <div className="container">
                    <div className="row d-flex align-items-end">
                        <div className="col-xl-6 col-12 text-xl-start text-center special-padding">
                            <h1 className={'font-roboto'}>Sign up and get started with SparxUp today.</h1>
                            <p className={'font-barlow mt-2'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Ut
                                elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                            <button className={'font-barlow mt-3'}>Sign Up</button>
                        </div>
                        <div className="col-xl-6 col-12 px-5">
                            <img className={'w-100'} src={graphOne} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'footer-section'}>
                <Footer/>
            </section>
        </div>
    );
};

export default ContactUs;