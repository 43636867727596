import React, {useState} from 'react';

import logo from "../images/logo.svg"
import {Link} from "react-router-dom";

const Navbar = () => {

    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(prevState => !prevState)
    };



    return (
        <section className={'navbar-sec container'}>
            <nav>
                <div className="row align-items-center">
                    <div className="col-xl-7 col-md-6 col-6 text-lg-start text-center">
                        <Link className={'mx-5'} to={'/'}><img src={logo} alt="logo"/></Link>
                    </div>
                    <div
                        className="col-xl-5 col-lg-5 d-md-none d-none d-xl-flex d-lg-flex justify-content-end align-items-center gap-5 pt-2">
                        <Link className={'font-barlow'} to="/">Home</Link>
                        <Link className={'font-barlow'} to="/contact-us">Contact Us</Link>
                        <Link to="/contact-us"> <button className={'font-barlow'}>Request a Trial</button></Link>
                    </div>

                    <div
                        className="col-md-6 col-6 d-lg-none d-flex justify-content-lg-end justify-content-center align-items-center gap-2 pt-2">
                        <button onClick={toggleMenu} className={'p-1'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                                 className="bi bi-list" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        </button>
                       <Link to="/contact-us"> <button className={'font-barlow'}>Request a Trial</button></Link>
                    </div>

                    {/*for mobile view menu links*/}
                    <div
                        className={`col-12 ${isActive ? 'mobile-view-links' : 'd-none'}`}>
                        <Link className={'font-barlow'} to="/">Home</Link>
                        <Link className={'font-barlow'} to="/contact-us">Contact Us</Link>
                    </div>
                    {/*for mobile view menu links*/}

                </div>
            </nav>
        </section>
    );
};

export default Navbar;