import React from "react";
import Slider from "react-slick";
import TestimonialCard from "./TestimonialCard";

const TestimonialCarousel = () => {

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        arrows:false
    };

    const getSlidesToShow = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 425) {
            return 1;
        }else{
            return 3;
        }
    };
    settings.slidesToShow = getSlidesToShow();


    return (
        <div className={'mt-5'}>
            <Slider {...settings}>
                <div>
                    <TestimonialCard elemId={1}/>
                </div>
                <div>
                    <TestimonialCard elemId={2}/>
                </div>
                <div>
                    <TestimonialCard elemId={3}/>
                </div>
                <div>
                    <TestimonialCard elemId={4}/>
                </div>
            </Slider>
        </div>
    );

}

export default TestimonialCarousel;