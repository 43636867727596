


export const faqsData = [
    {
        id: '1',
        question:'How is data linked to DSAnalytix?',
        answer: 'We use both API Integration and manual Excel Sheets to link RAW data of your Seller Account.'
    },
    {
        id: '2',
        question:'Is Module-1 only focused on building reports?',
        answer: 'Module-1 is where your data is developed in formats and data recording sheets. These data points are then used to develop reports. Module-1 serves as an automatic system of formatting and data reporting.',
    },
    {
        id: '3',
        question:'What are the KPIs of the Dashboard?',
        answer: 'DSAnalytix has identified a list of KPI that are crucial to be part of an ecommerce ecosystem. For Investors Selling on Amazon and managing a big business with multiple teams, DSA Dashboard enable a live interaction with critical business KPIs in a single glance.',
    },
    {
        id: '4',
        question:'Can DSA also manage part of our business supply chain?',
        answer: 'DSA team is equipped with supply chain experts which have experience in handling and developing supply chains of products.',
    },
    {
        id: '5',
        question:'Can we get a Demo of all 3 Modules?',
        answer: 'DSA will receive your Demo Request via Contact Us Page and contact you regarding a Demo. It will contain previous working with Ecommerce Accounts.',
    },

]