import React from 'react';

import star from '../images/star-fill.svg';

import {testimonialData} from "../data/testimonialData";

const TestimonialCard = ({elemId}) => {

    const renderTestimonials = testimonialData.map((elem) => {
        if (elemId === elem.id) {
            return (
                <div className={'testimonial-card bg-white mx-3'} key={elem.id}>
                    <div className="row p-2">
                        <div className="col-12">
                            <div className={'d-flex gap-2'}>
                                <li><img src={star} alt="star"/></li>
                                <li><img src={star} alt="star"/></li>
                                <li><img src={star} alt="star"/></li>
                                <li><img src={star} alt="star"/></li>
                                <li><img src={star} alt="star"/></li>
                            </div>
                            <p className={'font-barlow'}>{elem.statement}</p>
                            <div className="row d-flex align-items-center">
                                <div className="col-xl-3 col-lg-5 col-md-12 col-3">
                                    <img className={'testimonial-image'} src={elem.image} alt=""/>
                                </div>
                                <div className="col-xl-8 col-lg-7 col-md-12 col-8 mt-xl-0 mt-lg-0 mt-md-3">
                                    <strong className={'font-roboto'}>{elem.name}</strong>
                                    <span className={'font-barlow d-block'}>{elem.designation}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    })

    return (
        <>
            {renderTestimonials}
        </>
    );
};

export default TestimonialCard;