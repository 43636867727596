import React from 'react';

import graphImage1 from '../images/graph_one.svg'
import {Link} from "react-router-dom";


const Banner = () => {
    return (
        <div className={'banner-heading-sec'}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-12 text-xl-start text-lg-center text-md-center text-center">
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className={'font-barlow'}>Welcome To Demand Supply Analytix </h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <h1 className={'font-roboto'}>Start Visualizing Your Ecommerce Supply
                                            Chain.</h1>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-11">
                                        <p className={'font-barlow'}>Complex Supply Chains need Data Driven support
                                            system to optimize end-to-end operations. We make your supply chain
                                            transparent so you can make the right decision for your business. </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="row mt-3">
                                        <div
                                            className="col-12 d-flex justify-content-xl-start justify-content-lg-center justify-content-md-center justify-content-center  align-items-center gap-4">
                                           <Link to="/contact-us"><button className={'font-barlow'}>Request a Trial</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-7 col-lg-12">
                        <img src={graphImage1} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;