import React, {useState} from 'react';

import {faqsData} from "../data/faqsData";

const Faq = () => {

    const [open, setOpen] = useState(-1);


    const renderFaqs = faqsData.map((elem) => {

        const isOpen = open === elem.id
        return (
            <div key={elem.id} className="row mt-2">
                <div className="col-12">
                    <button onClick={() => setOpen(isOpen ? -1 : elem.id)}
                            className="text-white d-flex justify-content-between align-items-center" type="button"
                            data-bs-toggle="collapse" data-bs-target={`#collapseExample${elem.id}`}
                            aria-expanded="false" aria-controls="collapseExample">
                        {elem.question}
                        {isOpen ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                       className="bi bi-caret-left" viewBox="0 0 16 16">
                                <path
                                    d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z"/>
                            </svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                   className="bi bi-caret-down" viewBox="0 0 16 16">
                                <path
                                    d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                            </svg>}
                    </button>
                    <div className="collapse" id={`collapseExample${elem.id}`}>
                        <div className="card card-body bg-transparent faq-answer-area">
                            {elem.answer}
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <>
            <div className="container p-5">
                <div className={'px-xl-5 px-0'}>
                    {renderFaqs}
                </div>
            </div>
        </>
    );
};

export default Faq;