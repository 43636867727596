import React from 'react';
import FeatureCard from "./FeatureCard";

const Features = () => {
    return (
        <div className={'feature'}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center d-flex justify-content-center align-items-center flex-column p-xl-0 p-5">
                        <h6 className={'font-barlow'}>MODULES</h6>
                        <h2 className={'font-roboto'}>Integrated Ecommerce Analytics and Supply Chain Management Platform</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <FeatureCard/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;