
import serviceOne from '../images/service_one.png';
import serviceTwo from '../images/service_two.png';
import serviceThree from '../images/service_three.png';


export const FeaturesData = [
    {
        title:'Ecommerce Data to Reports',
        description: 'We manage your data sheets, generate insightful reports, and ensure smooth operations. ',
        icon:serviceOne,
    },
    {
        title:'Reports to Dashboards',
        description: 'Leverage custom KPIs to optimize your supply chain. Get valuable insights and make data-driven decisions effectively.',
        icon:serviceTwo,
    },
    {
        title:'Overall Supply Chain Support',
        description: 'Entrust us with your ecommerce store. From Demand to supply, we ensure smooth operations for your store.',
        icon:serviceThree,
    },
]