import React, {useEffect} from 'react';
import moduleImg_Three from '../images/module_three.png';
import mOne from '../images/mOne.png';
import mTwo from '../images/mTwo.png';
import dotPattern from '../images/dot_pattern.png';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';

const Content = () => {
    // Subsection One
    const {ref: refOne, inView: inViewOne} = useInView({threshold: 0.3});
    const animationOne = useAnimation();

    useEffect(() => {
        if (inViewOne) {
            animationOne.start({
                opacity: 1,
                transition: {
                    duration: 1.5,
                },
            });
        } else {
            animationOne.start({
                opacity: 0,
            });
        }
    });

    // Subsection Two
    const {ref: refTwo, inView: inViewTwo} = useInView({threshold: 0.3});
    const animationTwo = useAnimation();

    useEffect(() => {
        if (inViewTwo) {
            animationTwo.start({
                opacity: 1,
                transition: {
                    duration: 1.5,
                },
            });
        } else {
            animationTwo.start({
                opacity: 0,
            });
        }
    });

    // Subsection Three
    const {ref: refThree, inView: inViewThree} = useInView({threshold: 0.3});
    const animationThree = useAnimation();

    useEffect(() => {
        if (inViewThree) {
            animationThree.start({
                opacity: 1,
                transition: {
                    duration: 1.5,
                },
            });
        } else {
            animationThree.start({
                opacity: 0,
            });
        }
    });

    return (
        <div>
            <div>
                <img className={'dot-pattern_one'} src={dotPattern} alt=""/>
            </div>
            <div className="container">
                {/* Subsection One */}
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className="font-roboto">Ecommerce Data to Reports</h2>
                        <p>In the fast-paced world of ecommerce, data is the cornerstone of success. Our transformative
                            Module 1 takes raw ecommerce data and unlocks its true potential, enabling you to
                            effectively manage and leverage it for optimal outcomes.</p>
                        <div className="row moduleOne_img ">
                            <motion.div className="col-12" animate={animationOne} ref={refOne}>
                                <img className={'mOne'} src={mOne} alt=""/>
                            </motion.div>
                        </div>
                    </div>
                </div>

                {/* Subsection Two */}
                <div className="row mt-5 py-5">
                    <div className="col-12 text-center">
                        <h2 className="font-roboto">Reports to Dashboards for Visualization</h2>
                        <p>Module 2 is the gateway to unlocking the true power of your ecommerce data. With its
                            robust visualization capabilities, this module transforms reports generated from Module 1
                            into interactive and visually captivating dashboards that provide actionable insights.</p>
                        <div className="row moduleOne_img text-center">
                            <motion.div className="col-12" animate={animationTwo} ref={refTwo}>
                                <img src={mTwo} alt=""/>
                            </motion.div>
                        </div>
                    </div>
                </div>

                {/* Subsection Three */}
                <div className="row mt-5 mb-5">
                    <div className="col-12 text-center">
                        <h2 className="font-roboto mt-5">A to Z Supply Chain Support</h2>
                        <p>We have a comprehensive solution that provides end-to-end support for managing your ecommerce
                            supply chain. From procurement and inventory management to order fulfillment and logistics,
                            this module ensures seamless operations and enhanced efficiency.</p>
                        <div className="row moduleOne_img text-center">
                            <motion.div className="col-12" animate={animationThree} ref={refThree}>
                                <img src={moduleImg_Three} alt=""/>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;