
import img1 from '../images/test_1.jpg';
import img2 from '../images/test_2.jpg';
import img3 from '../images/test_4.jpg';
import img4 from '../images/test_3.jpg';

export const testimonialData = [
    {
        id: 1,
        name:'Rian Boshings',
        designation:'Founder NextDIV',
        statement: 'DSA team transformed our sales strategy with insightful Ecommerce Analytics, leading to remarkable growth.',
        image:img1
    },
    {
        id: 2,
        name:'Adam Levonski',
        designation:'Manger SC IT Boost',
        statement: 'Efficient decision-making achieved through clear, informative dashboards by DSAnalytix. Highly Recommended!',
        image:img2
    },
    {
        id: 3,
        name:'Hamza Rehman',
        designation:'Managing Partner Reactx Build',
        statement: 'DSAnalytix\'s Excel expertise optimized our Inventory processes. Complex problem but a simple solution approach. Thumbs up.',
        image:img3
    },
    {
        id: 4,
        name:'Jack Vitrot',
        designation:'Head of Operations SS Solutions',
        statement: 'Our day to day Logistics and tracking was a major issue. DS Team dealt with all process streamlining and this is my go to people now.',
        image:img4
    },
]