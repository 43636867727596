import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './App.css';


import HomePage from "./components/HomePage";
import {Route, Routes} from "react-router-dom";
import ContactUs from "./components/ContactUs";


function App() {
    return (
        <div className="App">
            <Routes>
                <Route path={'/'} element={<HomePage/>}/>
                <Route path={'/contact-us'} element={<ContactUs/>}/>
            </Routes>
        </div>
    );
}

export default App;
