import React from 'react';
import Banner from "./Banner";
import Navbar from "./Navbar";
import Carousel from "./Carousel";
import Features from "./Features";
import Footer from "./Footer";


import graphOne from '../images/graph_one.svg'
import Faq from "./Faq";
import TestimonialCarousel from "./TestimonialCarousel";
import Content from "./Content";
import {Link} from "react-router-dom";

const HomePage = () => {
    return (
        <div className={'home-main'}>
            <section className={'banner-main'}>
                <Navbar/>
                <Banner/>
            </section>
            <section className={'company-carousel'}>
                <div>
                    <h1 className={'font-roboto text-center'}>Over 1000+ E-Commerce Sellers worldwide rely on
                        DSAnalytix</h1>
                    <p className={'font-barlow mt-3 mb-5 text-center'}>We have a premium client base and aspire to be
                        the leader in developing and optimizing Supply Chain Operations for Ecommerce Stores</p>
                </div>
                <div className={'mt-2'}>
                    <Carousel/>
                </div>
            </section>
            <section className={'feature-section'}>
                <Features/>
            </section>
            <section className={'content-section'}>
                <Content/>
            </section>
            <section className={'testimonial-section'}>
                <div className="container">
                    <div className="row testimonial-content">
                        <div className="col-12">
                            <h6 className={'font-barlow text-white text-center'}>TESTIMONIAL</h6>
                            <h2 className={'font-roboto text-white text-center'}>What They Say</h2>
                            <TestimonialCarousel/>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'faq-section'}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className={'text-center'}>
                                <h6 className={'font-barlow'}>FAQ</h6>
                                <h2 className={'font-roboto mt-3'}>Frequently Ask Questions</h2>
                                <p className={'font-barlow mt-4'}>Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                            </div>
                            <Faq/>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'footer-banner'}>
                <div className="container">
                    <div className="row d-flex align-items-end">
                        <div className="col-xl-6 col-12 text-xl-start text-center special-padding">
                            <h1 className={'font-roboto'}>Request a trial and get started with Dsanalytix today.</h1>
                            <Link to={'/contact-us'} ><button className={'font-barlow mt-5'}>Request a trial</button></Link>
                        </div>
                        <div className="col-xl-6 col-12 px-5">
                            <img className={'w-100'} src={graphOne} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'footer-section'}>
                <Footer/>
            </section>
        </div>
    );
};

export default HomePage;