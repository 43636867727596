import React from 'react';

import {FeaturesData} from "../data/featuresData"

const FeatureCard = () => {

    const renderFeatureCard = FeaturesData.map((elem) => {
        return (
            <div className={'feature-card'}>
                <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                        <img src={elem.icon} alt=""/>
                        <h3 className={'font-roboto text-center mt-4'}>{elem.title}</h3>
                        <p className={'text-center font-barlow'}>{elem.description}</p>
                    </div>
                </div>
            </div>
        )
    });

    return (
        <>
            <div className="row">
                <div className="col-12 d-flex flex-wrap gap-xl-3 gap-md-3 p-xl-5 p-0">
                    {renderFeatureCard}
                </div>
            </div>
        </>
    );
};

export default FeatureCard;